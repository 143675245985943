import { useState, useRef, RefObject, useEffect } from "react";
import { memo } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import useTranslate from "../../../../../apps/web/app/hooks/useTranslate";

export const OptionSelect = memo(
  ({
    label,
    placeholder,
    required,
    options,
    variants,
    type,
    value,
    onSelect,
    onIndex,
    className,
  }: {
    label?: String;
    placeholder?: String;
    required?: Boolean;
    options: string[];
    variants?: any;
    type?: String;
    value?: String;
    onSelect?: any;
    onIndex?: any;
    className?: String;
  }) => {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");

    const changeRef: RefObject<HTMLSelectElement> =
      useRef<HTMLSelectElement>(null);

    const handleOptionClick = (value: string) => {
      if (changeRef.current !== null) {
        changeRef.current.value = value;
        setSelectedValue(changeRef.current.value);
        onSelect(changeRef.current.value);
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
      document.addEventListener("touchstart", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
        document.removeEventListener("touchstart", handleClickOutside, true);
      };
    }, []);
    const selectRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: any) => {
      if (selectRef.current) {
        if (!selectRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    const { translate } = useTranslate();

    return (
      <>
        <div ref={selectRef}>
          <span>{label}</span>
          <span className="font-bold text-red-500">{required ? " *" : ""}</span>
          <div onClick={() => setOpen((prev) => !prev)} className="relative ">
            <div
              className={`mt-2 flex cursor-pointer items-center justify-between bg-[#f5f5f5] py-2 pl-6 pr-3 outline-none ${className}`}
            >
              <span>{selectedValue ? selectedValue : translate("choose")}</span>
              <span>
                <ChevronDownIcon
                  className={`w-4 ${
                    open
                      ? "transition-transform"
                      : "-rotate-180 transition-transform"
                  }`}
                />
              </span>
            </div>
            <ul
              className={`scrolling-touch absolute z-[900] w-full overflow-y-scroll bg-gray-50 py-2 shadow-sm drop-shadow-lg transition duration-300 ease-in-out lg:max-h-[200px] ${
                open ? "opacity-100" : "hidden max-h-0 opacity-0"
              }`}
              style={{
                overscrollBehavior: "contain",
                WebkitOverflowScrolling: "touch",
              }}
            >
              {options &&
                options.map((option, index) => (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOptionClick(option);
                      setOpen(false);
                      onIndex(index);
                    }}
                    className="z-[999] cursor-default px-6 py-2 text-black hover:bg-sky-200 focus:bg-sky-200 lg:cursor-pointer"
                    key={index}
                  >
                    {option}
                    {variants
                      ? String(
                          parseInt(
                            variants[type === "material" ? index * 4 : index]
                              .price.amount
                          ) - parseInt(variants[0].price.amount)
                        ) > "0"
                        ? " ( +" +
                          String(
                            parseInt(
                              variants[type === "material" ? index * 4 : index]
                                .price.amount
                            ) - parseInt(variants[0].price.amount)
                          ) +
                          " " +
                          variants[0].price.currencyCode +
                          " )"
                        : ""
                      : ""}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <select
          className="hidden"
          ref={changeRef}
          onChange={(event) => {
            onSelect(event.target.value);
          }}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </>
    );
  }
);
