import { useState, useRef, memo, RefObject, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { Upload } from "../../../../apps/web/app/components/upload";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.min.css";

import video1 from "../../assets/images/video1.png";
import video2 from "../../assets/images/video2.png";
import video3 from "../../assets/images/video3.png";
import video4 from "../../assets/images/video4.png";
import bannerCustom from "../../assets/images/bannerCustom.png";

import { OptionSelect } from "./optionSelect";
import { FontSelect } from "./fontSelect";

import Uppy from "@uppy/core";
import useTranslate from "../../../../apps/web/app/hooks/useTranslate";
import { Gallery } from "../gallery";

const uppy = new Uppy();

interface IconProps {
  id: number;
  open: number;
}

function Icon({ id, open }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export const ProductForm = memo(
  ({
    uppy,
    product,
    count,
    variants,
    value,
    onSelect,
    onVariantIndex,
    onUrlsChange,
    errors,
    handle,
  }: {
    uppy: Uppy;
    product: any;
    count: number;
    variants?: any;
    value: {};
    onSelect: any;
    onVariantIndex?: any;
    onUrlsChange?: any;
    errors?: any;
    handle?: string;
  }) => {
    const [firstOpen, setFirstOpen] = useState(1);
    const [secondOpen, setSecondOpen] = useState(1);
    const [thirdOpen, setThirdOpen] = useState(1);
    const [fourthOpen, setFourthOpen] = useState(1);

    const handleFirstOpen = (value: any) => {
      setFirstOpen(firstOpen === value ? 0 : value);
    };
    const handleSecondOpen = (value: any) => {
      setSecondOpen(secondOpen === value ? 0 : value);
    };
    const handleThirdOpen = (value: any) => {
      setThirdOpen(thirdOpen === value ? 0 : value);
    };
    const handleFourthOpen = (value: any) => {
      setFourthOpen(fourthOpen === value ? 0 : value);
    };

    const uploadRef: RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);

    const handleClick = () => {
      if (uploadRef.current !== null) {
        uploadRef.current.click();
      }
    };

    const [selectedValue, setSelectedValue] = useState("");

    const [formData, setFormData] = useState({
      producer: "",
      year: "",
      model: "",
      capacity: "",
      standardPlastics: "",
      plasticDetails: "",
      riderName: "",
      riderNumber: "",
      numberBackground: "",
      numberColor: "",
      numberFont: "",
      logoOrder: "",
      sticker: "",
      rolling: "",
      otherDetails: "",
    });

    useEffect(() => {
      onSelect(formData);
    }, [formData]);

    const [open, setOpen] = useState(false);
    const [secondDialogOpen, setSecondDialogOpen] = useState(false);
    const [thirdDialogOpen, setThirdDialogOpen] = useState(false);
    const [fourthDialogOpen, setFourthDialogOpen] = useState(false);

    const handleOpen = () => setOpen(!open);
    const handleSecondDialogOpen: any = () =>
      setSecondDialogOpen(!secondDialogOpen);
    const handleThirdDialogOpen: any = () =>
      setThirdDialogOpen(!thirdDialogOpen);
    const handleFourthDialogOpen: any = () =>
      setFourthDialogOpen(!fourthDialogOpen);

    /** Aici se face logica pentru erori */

    const [formErrors, setFormErrors] = useState({
      producer: "",
      year: "",
      model: "",
      capacity: "",
      standardPlastics: "",
      plasticDetails: "",
      riderName: "",
      riderNumber: "",
      numberBackground: "",
      numberColor: "",
      numberFont: "",
      logoOrder: "",
      sticker: "",
      rolling: "",
      otherDetails: "",
    });

    const producerRef = useRef<HTMLDivElement | null>(null);
    const yearRef = useRef<HTMLDivElement | null>(null);
    const modelRef = useRef<HTMLDivElement | null>(null);
    const capacityRef = useRef<HTMLDivElement | null>(null);
    const standardPlasticsRef = useRef<HTMLDivElement | null>(null);
    const plasticDetailsRef = useRef<HTMLDivElement | null>(null);
    const stickerRef = useRef<HTMLDivElement | null>(null);
    const rollingRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (errors) {
        if (errors.errors.producer) {
          if (producerRef && producerRef.current) {
            const scrollPosition =
              producerRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.year) {
          if (yearRef && yearRef.current) {
            const scrollPosition =
              yearRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.model) {
          if (modelRef && modelRef.current) {
            const scrollPosition =
              modelRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.capacity) {
          if (capacityRef && capacityRef.current) {
            const scrollPosition =
              capacityRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.standardPlastics) {
          if (standardPlasticsRef && standardPlasticsRef.current) {
            const scrollPosition =
              standardPlasticsRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.plasticDetails) {
          if (plasticDetailsRef && plasticDetailsRef.current) {
            const scrollPosition =
              plasticDetailsRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.sticker) {
          if (stickerRef && stickerRef.current) {
            const scrollPosition =
              stickerRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        if (errors.errors.rolling) {
          if (rollingRef && rollingRef.current) {
            const scrollPosition =
              rollingRef.current.getBoundingClientRect().top +
              window.pageYOffset -
              150;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
      }
    }, [errors]);

    useEffect(() => {
      if (errors) {
        setFormErrors(
          errors.errors || {
            producer: "",
            year: "",
            model: "",
            capacity: "",
            standardPlastics: "",
            plasticDetails: "",
            riderName: "",
            riderNumber: "",
            numberBackground: "",
            numberColor: "",
            numberFont: "",
            logoOrder: "",
            sticker: "",
            rolling: "",
            otherDetails: "",
          }
        );
      }
    }, [errors]);

    /** Aici se face logica pentru variante */

    const [defaultVariant, setDefaultVariant] = useState(variants[0]);

    const [materialSet, setMaterialSet] = useState<Set<string>>(
      new Set<string>()
    );
    const [rollingSet, setRollingSet] = useState<Set<string>>(
      new Set<string>()
    );

    const [materialIndex, setMaterialIndex] = useState(0);
    const [rollingIndex, setRollingIndex] = useState(0);

    useEffect(() => {
      setDefaultVariant(
        variants[materialIndex * rollingSet.size + rollingIndex]
      );
      onVariantIndex(materialIndex * rollingSet.size + rollingIndex);
    }, [materialIndex, rollingIndex, rollingSet]);

    const handleMaterialIndexChange = (value: any) => {
      setMaterialIndex(value);
    };

    const handleRollingIndexChange = (value: any) => {
      setRollingIndex(value);
    };

    useEffect(() => {
      variants.forEach((variant: any) => {
        const [before, after] = variant.title.split(" / ");
        setMaterialSet((prevSet) => new Set(prevSet).add(before));
        setRollingSet((prevSet) => new Set(prevSet).add(after));
      });
    }, [variants]); // Only run the effect when variants change

    /** Aici se face logica pentru url-uri */

    const [urls, setUrls] = useState<Set<string>>(new Set());

    const handleUrlsChange = (value: Set<string>) => {
      setUrls(value);
    };

    useEffect(() => {
      onUrlsChange(urls);
    }, [urls]);

    const [galleryOpen, setGalleryOpen] = useState<boolean>(false);
    const [galleryIndex, setGalleryIndex] = useState<number>(0);

    const handleGalleryOpen = (value: number) => {
      setGalleryOpen((prev) => !prev);
      setGalleryIndex(value);
    };
    const [activeIndex, setActiveIndex] = useState(0);

    const { translate } = useTranslate();

    /** This is the logic for the slider "/3" division of images */
    const [slides, setSlides] = useState<
      { img1: string; img2: string; img3: string }[]
    >([]);

    useEffect(() => {
      let imageArray: { img1: string; img2: string; img3: string }[] = [];
      let image = {
        img1: "",
        img2: "",
        img3: "",
      };
      product.media?.nodes.map((node: any, index: number) => {
        if (index % 3 === 0) {
          image.img1 = node.image?.url;
          if (index === product.media?.nodes.length - 1) {
            imageArray.push(image);
          }
        }
        if (index % 3 === 1) {
          image.img2 = node.image?.url;
          if (index === product.media?.nodes.length - 1) {
            imageArray.push(image);
          }
        }
        if (index % 3 === 2) {
          image.img3 = node.image?.url;
          imageArray.push(image);
          image = {
            img1: "",
            img2: "",
            img3: "",
          };
        }
      });
      setSlides(imageArray);
    }, []);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    return (
      <div className="font-poppins bg-white pt-10">
        {handle !== "custom-design" ? (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation={slides.length > 1}
          >
            {slides.map(
              (
                slide: {
                  img1: string;
                  img2: string;
                  img3: string;
                },
                bigIndex: number
              ) => (
                <div key={`Slide ${bigIndex}`}>
                  <SwiperSlide>
                    <div className="grid grid-cols-2 lg:mx-auto lg:grid-cols-3 lg:grid-rows-2 lg:px-8">
                      <div className="col-span-2 lg:row-span-2">
                        {slide.img1 && (
                          <img
                            onClick={() => {
                              setCurrentIndex(bigIndex * 3);
                              setModalOpen(true);
                            }}
                            src={slide.img1}
                            alt="img"
                          />
                        )}
                      </div>
                      <div className="col-span-1 lg:col-start-3">
                        {slide.img2 && (
                          <img
                            onClick={() => {
                              setCurrentIndex(bigIndex * 3 + 1);
                              setModalOpen(true);
                            }}
                            src={slide.img2}
                            alt="img"
                          />
                        )}
                      </div>
                      <div className="col-span-1 lg:col-start-3 lg:row-start-2">
                        {slide.img3 && (
                          <img
                            onClick={() => {
                              setCurrentIndex(bigIndex * 3 + 2);
                              setModalOpen(true);
                            }}
                            src={slide.img3}
                            alt="img"
                          />
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              )
            )}
          </Swiper>
        ) : (
          <img src={bannerCustom} alt="custom" />
        )}
        <Gallery
          open={modalOpen}
          onClose={setModalOpen}
          images={(() => {
            return product.media.nodes.map((node: any) => node.image.url);
          })()}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
        <div className="max-w-screen-2xl px-8 lg:mx-auto lg:py-5 2xl:px-0">
          <p className="mt-10 hidden text-center font-[300] lg:block">
            {translate("disclaimerProduct")}
          </p>
          <form onSubmit={(e) => e.preventDefault()}>
            <Accordion
              className="mt-8"
              open={firstOpen === 1}
              icon={<Icon id={1} open={firstOpen} />}
            >
              <AccordionHeader
                className="border-b-[3px] border-black py-2 pr-1.5 text-black"
                onClick={() => handleFirstOpen(1)}
              >
                <span className="font-montserrat py-0 text-base font-[500] text-black lg:font-semibold">
                  1. {translate("formH1")}
                </span>
              </AccordionHeader>
              <AccordionBody
                className="font-poppins mt-3 h-full gap-x-[68px] pb-24 text-black"
                cont
              >
                <div className="flex flex-col lg:flex-row lg:gap-[68px]">
                  <div ref={producerRef} className="mb-3 lg:w-1/2">
                    <OptionSelect
                      className={`${
                        formErrors
                          ? formErrors.producer
                            ? "border-[1.5px] border-red-400 "
                            : ""
                          : ""
                      }`}
                      label={translate("formQ1")}
                      placeholder={translate("choose")}
                      options={[
                        "KTM",
                        "Yamaha",
                        "Suzuki",
                        "Kawasaki",
                        "Husqvarna",
                        "GasGas",
                        "Beta",
                        "Sherco",
                        "Honda",
                        "Husaberg",
                        "Other",
                      ]}
                      required={true}
                      value={selectedValue}
                      onSelect={(value: any) => {
                        setFormData({
                          ...formData,
                          producer: value,
                        });
                        setFormErrors({
                          ...formErrors,
                          producer: "",
                        });
                      }}
                    />
                    <p className="mt-2 text-sm text-red-500">
                      {formErrors
                        ? formErrors.producer
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                  <div ref={yearRef} className="mb-3 flex flex-col lg:w-1/2">
                    <label htmlFor="rider-name">
                      {translate("formQ2")}
                      <span className="font-bold text-red-500">*</span>
                    </label>
                    <input
                      required
                      className={`placeholder mt-2 border-[1.5px] border-[1.5] bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic ${
                        formErrors
                          ? formErrors.year
                            ? "border-red-400"
                            : ""
                          : ""
                      }`}
                      placeholder={translate("typeHere")}
                      type="number"
                      id="rider-name"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          year: event.target.value,
                        });
                        setFormErrors({
                          ...formErrors,
                          year: "",
                        });
                      }}
                    ></input>
                    <p className="mt-2 text-red-500">
                      {formErrors
                        ? formErrors.year
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-[68px]">
                  <div ref={modelRef} className="mb-3 flex flex-col lg:w-1/2">
                    <label htmlFor="model">
                      {translate("formQ3")}
                      <span className="font-bold text-red-500">*</span>
                    </label>
                    <input
                      className={`mt-2 border bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic ${
                        formErrors
                          ? formErrors.model
                            ? "border-red-400"
                            : ""
                          : ""
                      }`}
                      placeholder={translate("typeHere")}
                      type="text"
                      id="rider-name"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          model: event.target.value,
                        });
                        setFormErrors({
                          ...formErrors,
                          model: "",
                        });
                      }}
                    ></input>
                    <p className="mt-2 text-red-500">
                      {formErrors
                        ? formErrors.model
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                  <div ref={capacityRef} className="mb-3 lg:w-1/2">
                    <OptionSelect
                      className={`${
                        formErrors
                          ? formErrors.capacity
                            ? "border-[1.5px] border-red-400"
                            : ""
                          : ""
                      }`}
                      label={translate("formQ4")}
                      placeholder="Alege"
                      options={["50cc", "65cc", "85cc", "110cc", "+125cc"]}
                      required={true}
                      onSelect={(value: any) => {
                        setFormData({
                          ...formData,
                          capacity: value,
                        });
                        setFormErrors({
                          ...formErrors,
                          capacity: "",
                        });
                      }}
                      value={selectedValue}
                    />
                    <p className="mt-2 text-red-500">
                      {formErrors
                        ? formErrors.capacity
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="mt-2 flex flex-col lg:flex-row lg:gap-[68px]">
                  <div ref={standardPlasticsRef} className="mb-3 lg:w-1/2">
                    <OptionSelect
                      className={`mt-2 bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic ${
                        formErrors
                          ? formErrors.standardPlastics
                            ? "border border-red-400"
                            : ""
                          : ""
                      }`}
                      label={translate("formQ5")}
                      placeholder="Alege"
                      options={["Yes", "No"]}
                      required={true}
                      onSelect={(value: any) => {
                        setFormData({
                          ...formData,
                          standardPlastics: value,
                        });
                        setFormErrors({
                          ...formErrors,
                          standardPlastics: "",
                        });
                      }}
                      value={selectedValue}
                    />
                    <p className="mt-2 text-red-500">
                      {formErrors
                        ? formErrors.standardPlastics
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                  <div className="mb-3 lg:w-1/2"></div>
                </div>
                {formData.standardPlastics === "No" && (
                  <div ref={plasticDetailsRef} className="mt-2 flex flex-col ">
                    <label htmlFor="engine-details">
                      Daca nu, detaliaza mai jos...
                    </label>
                    <textarea
                      className={`mt-1.5 min-h-[100px] border bg-[#f0f0f0] px-4 py-2 outline-none ${
                        formErrors
                          ? formErrors.plasticDetails
                            ? "border-red-400"
                            : ""
                          : ""
                      }`}
                      id="engine-details"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          plasticDetails: event.target.value,
                        });
                        setFormErrors({
                          ...formErrors,
                          plasticDetails: "",
                        });
                      }}
                    ></textarea>
                    <p className="mt-2 text-sm text-red-500">
                      {formErrors
                        ? formErrors.plasticDetails
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                )}
              </AccordionBody>
            </Accordion>
            <Accordion
              className={`${firstOpen ? "-mt-16" : "mt-8"}`}
              open={secondOpen === 1}
              icon={<Icon id={1} open={secondOpen} />}
            >
              <AccordionHeader
                className="border-b-[3px] border-black py-2 pr-1.5 text-black"
                onClick={() => handleSecondOpen(1)}
              >
                <span className="font-montserrat py-0 text-base font-[500] lg:font-semibold">
                  2. {translate("formH2")}
                </span>
              </AccordionHeader>
              <AccordionBody className="font-poppins mt-3 flex flex-col text-black">
                <div className="grid lg:grid-cols-2 lg:gap-x-[68px]">
                  <div className="flex flex-col">
                    <label htmlFor="rider-name">{translate("formQ6")}</label>
                    <input
                      className="placeholder mt-2 bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic"
                      placeholder={translate("leaveEmpty")}
                      type="text"
                      id="rider-name"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          riderName: event.target.value,
                        });
                      }}
                    ></input>
                  </div>
                  <div className="flex flex-col justify-start">
                    <label className="mt-4 lg:mt-0" htmlFor="rider-number">
                      {translate("formQ7")}
                    </label>
                    <input
                      className="placeholder mt-2 bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic"
                      placeholder={translate("leaveEmpty")}
                      type="text"
                      id="rider-number"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          riderNumber: event.target.value,
                        });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 lg:gap-x-[68px]">
                  <div className="flex flex-col">
                    <label className="mt-4" htmlFor="bg-color">
                      {translate("formQ8")}
                    </label>
                    <input
                      className="placeholder mt-2 bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic"
                      placeholder={translate("desiredColor")}
                      type="text"
                      id="bg-color"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          numberBackground: event.target.value,
                        });
                      }}
                    ></input>
                  </div>
                  <div className="mt-4 flex flex-col lg:mt-0">
                    <label className="lg:mt-4" htmlFor="nr-color">
                      {translate("formQ9")}
                    </label>
                    <input
                      className="placeholder mt-2 bg-[#f0f0f0] px-6 py-2 outline-none placeholder:italic"
                      placeholder={translate("desiredColor")}
                      type="text"
                      id="nr-color"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          numberColor: event.target.value,
                        });
                      }}
                    ></input>
                  </div>
                </div>
                <FontSelect
                  className="mt-4"
                  type="image"
                  label={translate("formQFont")}
                  required={false}
                  onSelect={(value: any) => {
                    setFormData({
                      ...formData,
                      numberFont: value,
                    });
                  }}
                />
              </AccordionBody>
            </Accordion>
            <Accordion
              className="mt-8"
              open={thirdOpen === 1}
              icon={<Icon id={1} open={thirdOpen} />}
            >
              <AccordionHeader
                className="border-b-[3px] border-black py-2 pr-1.5 text-black"
                onClick={() => handleThirdOpen(1)}
              >
                <span className="font-montserrat py-0 text-base font-[500] lg:font-semibold">
                  3.{" "}
                  {handle === "custom-design"
                    ? translate("formH3.1")
                    : translate("formH3")}
                </span>
              </AccordionHeader>
              <AccordionBody className="font-poppins mt-3 flex flex-col text-black">
                <label htmlFor="engine-details">{translate("formQ10")}</label>
                <textarea
                  className="mt-1.5 min-h-[100px] bg-[#f0f0f0] px-4 py-2 outline-none"
                  id="engine-details"
                ></textarea>
                <p className="mt-8">
                  {handle === "custom-design"
                    ? translate("formQ11.1")
                    : translate("formQ11")}
                </p>
                <Upload
                  uppy={uppy}
                  height="200"
                  width="400"
                  className="mt-3"
                  onUrlsChange={handleUrlsChange}
                />
              </AccordionBody>
            </Accordion>
            <Accordion
              className="mt-8"
              open={fourthOpen === 1}
              icon={<Icon id={1} open={fourthOpen} />}
            >
              <AccordionHeader
                className="border-b-[3px] border-black py-2 pr-1.5 text-black"
                onClick={() => handleFourthOpen(1)}
              >
                <span className="font-montserrat py-0 text-base font-[500] lg:font-semibold">
                  4. {translate("formH4")}
                </span>
              </AccordionHeader>
              <AccordionBody className="font-poppins mt-3 flex flex-col text-black">
                <div className="grid grid-cols-2 gap-5 md:flex">
                  <div className="flex max-w-[137px] flex-col gap-1">
                    <div className="relative">
                      <img src={video1} alt="img" />
                      <span
                        onClick={() => setOpen((prev) => !prev)}
                        className="absolute left-1/2 top-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-40"
                      >
                        <svg
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.25 21.5C1.59375 21.4688 1.0625 21.25 0.65625 20.8438C0.21875 20.4062 0 19.875 0 19.25V2.75C0 2.125 0.21875 1.59375 0.65625 1.15625C1.0625 0.75 1.59375 0.53125 2.25 0.5C2.65625 0.5 3.04688 0.609375 3.42188 0.828125L16.9219 9.07812C17.6094 9.54688 17.9688 10.1875 18 11C17.9688 11.8125 17.6094 12.4531 16.9219 12.9219L3.42188 21.1719C3.04688 21.3906 2.65625 21.5 2.25 21.5Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                    <span className="md:mt-2">{translate("formQ12")}</span>
                    <Dialog
                      size="xs"
                      className="font-poppins min-w-[300px]"
                      open={open}
                      handler={handleOpen}
                    >
                      <DialogHeader>
                        <p className="text-base lg:text-lg">
                          {translate("formQ12")}
                        </p>
                      </DialogHeader>
                      <DialogBody divider>
                        <video
                          className="max-h-[300px] w-full"
                          autoPlay
                          muted
                          controls
                          src="/assets/videos/holo-sparkle.mp4"
                        ></video>
                      </DialogBody>
                      <DialogFooter>
                        <button
                          className="px-3 py-1 text-sm font-semibold uppercase"
                          color="green"
                          onClick={handleOpen}
                        >
                          <span>Close</span>
                        </button>
                      </DialogFooter>
                    </Dialog>
                  </div>
                  <div className="flex max-w-[137px] flex-col gap-1">
                    <div className="relative">
                      <img src={video2} alt="img" />
                      <span
                        onClick={() => setSecondDialogOpen((prev) => !prev)}
                        className="absolute left-1/2 top-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-40"
                      >
                        <svg
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.25 21.5C1.59375 21.4688 1.0625 21.25 0.65625 20.8438C0.21875 20.4062 0 19.875 0 19.25V2.75C0 2.125 0.21875 1.59375 0.65625 1.15625C1.0625 0.75 1.59375 0.53125 2.25 0.5C2.65625 0.5 3.04688 0.609375 3.42188 0.828125L16.9219 9.07812C17.6094 9.54688 17.9688 10.1875 18 11C17.9688 11.8125 17.6094 12.4531 16.9219 12.9219L3.42188 21.1719C3.04688 21.3906 2.65625 21.5 2.25 21.5Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                    <span className="md:mt-2">{translate("formQ13")}</span>
                    <Dialog
                      size="xs"
                      className="font-poppins min-w-[300px]"
                      open={secondDialogOpen}
                      handler={handleSecondDialogOpen}
                    >
                      <DialogHeader>
                        <p className="text-base lg:text-lg">
                          {translate("formQ13")}
                        </p>
                      </DialogHeader>
                      <DialogBody divider>
                        <video
                          className="max-h-[300px] w-full"
                          autoPlay
                          muted
                          controls
                          src="/assets/videos/holo-glossy.mp4"
                        ></video>
                      </DialogBody>
                      <DialogFooter>
                        <button
                          className="bg-white px-3 py-1 text-sm font-semibold uppercase"
                          color="green"
                          onClick={handleSecondDialogOpen}
                        >
                          <span>Close</span>
                        </button>
                      </DialogFooter>
                    </Dialog>
                  </div>
                  <div className="flex max-w-[137px] flex-col gap-1">
                    <div className="relative">
                      <img src={video3} alt="img" />
                      <span
                        onClick={() => setThirdDialogOpen((prev) => !prev)}
                        className="absolute left-1/2 top-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-40"
                      >
                        <svg
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.25 21.5C1.59375 21.4688 1.0625 21.25 0.65625 20.8438C0.21875 20.4062 0 19.875 0 19.25V2.75C0 2.125 0.21875 1.59375 0.65625 1.15625C1.0625 0.75 1.59375 0.53125 2.25 0.5C2.65625 0.5 3.04688 0.609375 3.42188 0.828125L16.9219 9.07812C17.6094 9.54688 17.9688 10.1875 18 11C17.9688 11.8125 17.6094 12.4531 16.9219 12.9219L3.42188 21.1719C3.04688 21.3906 2.65625 21.5 2.25 21.5Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                    <span className="md:mt-2">{translate("formQ14")}</span>
                    <Dialog
                      size="xs"
                      className="font-poppins min-w-[300px]"
                      open={thirdDialogOpen}
                      handler={handleThirdDialogOpen}
                    >
                      <DialogHeader>
                        <p className="text-base lg:text-lg">
                          {translate("formQ14")}
                        </p>
                      </DialogHeader>
                      <DialogBody divider>
                        <video
                          className="max-h-[300px] w-full"
                          autoPlay
                          muted
                          controls
                          src="/assets/videos/holo-mat.mp4"
                        ></video>
                      </DialogBody>
                      <DialogFooter>
                        <button
                          className="bg-white px-3 py-1 text-sm font-semibold uppercase"
                          color="green"
                          onClick={handleThirdDialogOpen}
                        >
                          <span>Close</span>
                        </button>
                      </DialogFooter>
                    </Dialog>
                  </div>
                  <div className="flex max-w-[137px] flex-col gap-1">
                    <div className="relative">
                      <img src={video4} alt="img" />
                      <span
                        onClick={() => setFourthDialogOpen((prev) => !prev)}
                        className="absolute left-1/2 top-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-40"
                      >
                        <svg
                          width="18"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.25 21.5C1.59375 21.4688 1.0625 21.25 0.65625 20.8438C0.21875 20.4062 0 19.875 0 19.25V2.75C0 2.125 0.21875 1.59375 0.65625 1.15625C1.0625 0.75 1.59375 0.53125 2.25 0.5C2.65625 0.5 3.04688 0.609375 3.42188 0.828125L16.9219 9.07812C17.6094 9.54688 17.9688 10.1875 18 11C17.9688 11.8125 17.6094 12.4531 16.9219 12.9219L3.42188 21.1719C3.04688 21.3906 2.65625 21.5 2.25 21.5Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                    <span className="md:mt-2">{translate("formQ15")}</span>
                    <Dialog
                      size="xs"
                      className="font-poppins min-w-[300px]"
                      open={fourthDialogOpen}
                      handler={handleFourthDialogOpen}
                    >
                      <DialogHeader>
                        <p className="text-base lg:text-lg">
                          {translate("formQ15")}
                        </p>
                      </DialogHeader>
                      <DialogBody divider>
                        <video
                          className="max-h-[300px] w-full"
                          autoPlay
                          muted
                          controls
                          src="/assets/videos/chrome-lucios.mp4"
                        ></video>
                      </DialogBody>
                      <DialogFooter>
                        <button
                          className="bg-white px-3 py-1 text-sm font-semibold uppercase"
                          color="green"
                          onClick={handleFourthDialogOpen}
                        >
                          <span>Close</span>
                        </button>
                      </DialogFooter>
                    </Dialog>
                  </div>
                </div>
                <div className="mt-4 grid lg:grid-cols-2 lg:gap-x-[68px]">
                  <div ref={stickerRef} className="flex flex-col">
                    <OptionSelect
                      className={`${
                        formErrors
                          ? formErrors.sticker
                            ? "border border-red-400"
                            : ""
                          : ""
                      }`}
                      label={translate("formQ16")}
                      placeholder="Alege"
                      options={Array.from(materialSet)}
                      required={true}
                      value={selectedValue}
                      variants={variants}
                      type="material"
                      onSelect={(value: any) => {
                        setFormData({
                          ...formData,
                          sticker: value,
                        });
                        setFormErrors({
                          ...formErrors,
                          sticker: "",
                        });
                      }}
                      onIndex={handleMaterialIndexChange}
                    />
                    <p className="mt-2 text-sm text-red-500">
                      {formErrors
                        ? formErrors.sticker
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                  <div
                    ref={rollingRef}
                    className="mt-4 flex flex-col justify-start lg:mt-0"
                  >
                    <OptionSelect
                      className={`${
                        formErrors
                          ? formErrors.rolling
                            ? "border border-red-400"
                            : ""
                          : ""
                      }`}
                      label={translate("formQ17")}
                      placeholder="Alege"
                      options={Array.from(rollingSet)}
                      required={true}
                      value={selectedValue}
                      variants={variants}
                      type="rolling"
                      onSelect={(value: any) => {
                        setFormData({
                          ...formData,
                          rolling: value,
                        });
                        setFormErrors({
                          ...formErrors,
                          rolling: "",
                        });
                      }}
                      onIndex={handleRollingIndexChange}
                    />
                    <p className="mt-2 text-sm text-red-500">
                      {formErrors
                        ? formErrors.rolling
                          ? "Please enter a valid value"
                          : ""
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="mt-4 flex flex-col">
                  <label htmlFor="other-details" className="w-3/4">
                    {handle === "custom-design"
                      ? translate("customDetails")
                      : translate("formQ18")}
                  </label>
                  <textarea
                    className="mt-1.5 min-h-[100px] bg-[#f0f0f0] px-4 py-2 outline-none"
                    id="other-details"
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        otherDetails: event.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              </AccordionBody>
            </Accordion>
            <div className="flex flex-col items-end gap-5 border-b-[3px] border-black py-6">
              <div className="flex flex-col items-end">
                <span className="text-sm font-light leading-tight">Extra:</span>
                <span className="text-xl font-semibold leading-tight">
                  {(defaultVariant.price.amount - variants[0].price.amount) *
                    count +
                    " " +
                    defaultVariant.price.currencyCode}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm font-light leading-tight">Total:</span>
                <span className="text-xl font-semibold leading-tight">
                  {parseInt(defaultVariant.price.amount) * count +
                    " " +
                    defaultVariant.price.currencyCode}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
);
